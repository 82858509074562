<template>
  <div class="right_cont">
    <div class="title flex_center_between_box">
      <div class="title_txt flex_center_start_box"><img src="../../assets/img/account/title_ic9.png" />Contact Information</div>
      <div class="btn_export flex_center" v-show="isEdit" @click="editForm"><img src="../../assets/img/account/edit_ic.png" />Edit</div>
      <div class="btn_export btn_save flex_center" v-show="!isEdit" @click="submitForm"><img src="../../assets/img/account/btn_save.png" />Save</div>
    </div>
    <div class="right_cont_box">
      <el-form ref="settingForm" label-position="left" :hide-required-asterisk="true" :model="settingForm" :rules="settingFormRules" label-width="206px" class="asset_form">
        <el-form-item label="Company Name:" prop="companyNameEn">
          <el-input v-model="settingForm.companyNameEn" :disabled="isEdit"></el-input>
        </el-form-item>
        <el-form-item label="Address1:" prop="addressEn">
            <el-input v-model="settingForm.addressEn" :disabled="isEdit" />
          </el-form-item>
          <el-form-item label="Address2:" prop="addressEn">
            <el-input v-model="settingForm.addressEnSecond" :disabled="isEdit" />
          </el-form-item>
        <el-form-item label="City:" prop="city">
          <el-input v-model="settingForm.city" :disabled="isEdit" />
        </el-form-item>
        <el-form-item label="State/Province:" prop="state">
          <el-input v-model="settingForm.state" :disabled="isEdit" />
        </el-form-item>
        <el-form-item label="Postal Code:" prop="zip">
          <el-input v-model="settingForm.zip" :disabled="isEdit" />
        </el-form-item>
        <el-form-item label="Country:" prop="country">
          <el-input v-model="settingForm.country" :disabled="isEdit"></el-input>
        </el-form-item>
        <el-form-item label="Full Name:" prop="Fullname">
            <el-input v-model="settingForm.fullName" :disabled="isEdit"></el-input>
          </el-form-item>
          <el-form-item label="Position:" prop="Position">
            <el-input v-model="settingForm.position" :disabled="isEdit"></el-input>
          </el-form-item>
        <el-form-item label="Phone Number:">
          <div v-show="!isEdit" class="phone1">
            <el-tag :key="tag" v-for="tag in phones" closable :disable-transitions="false" @close="handleClose(tag)">
              {{ tag }}
            </el-tag>
            <el-input class="input-new-tag" v-model="phone" @blur="blurPhone"></el-input>
          </div>
          <div v-show="isEdit" class="phone">
            <el-tag :key="index" v-for="(tag, index) in phones">
              {{ tag }}
            </el-tag>
          </div>
        </el-form-item>
        <el-form-item label="Email:">
          <div v-show="!isEdit" class="phone1">
            <el-tag :key="tag" v-for="tag in emails" closable :disable-transitions="false" @close="handleClose1(tag)">
              {{ tag }}
            </el-tag>
            <el-input class="input-new-tag" v-model="email" @blur="blurEmail"></el-input>
          </div>
          <div v-show="isEdit" class="phone">
            <el-tag :key="index" v-for="(tag, index) in emails" :disable-transitions="false">
              {{ tag }}
            </el-tag>
          </div>
        </el-form-item>
        <el-form-item label="Website URL:" prop="url">
          <el-input v-model="settingForm.url" :disabled="isEdit" />
        </el-form-item>

 
      </el-form>
    </div>
  </div>
</template>
<script>
import { updateAccount } from '@/api/userApi.js'
export default {
  data() {
    //邮箱校验
    // var validateEmail = (rule, value, callback) => {
    //     if (value !== '') {
    //         let reg = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
    //         if (!reg.test(value)) {
    //             callback(new Error('Incorrect account format'))
    //         } else {
    //             callback()
    //         }
    //     } else {
    //         callback()
    //     }
    // }
    return {
      phones: [],
      emails: [],
      isEdit: true,
      settingForm: {
        companyNameEn: '',
        country: '',
        city: '',
        state: '',
        addressEn: '',
        zip: '',
        url: '',
        phones: [],
        emails: [],
        type: 0,
        fullName:'',
        position:'',
      },
      phone: '',
      email: '',
      settingFormRules: {
        companyNameEn: [{ required: true, message: 'Please enter the Company Name', trigger: 'blur' }],
        country: [{ required: true, message: 'Please enter the country', trigger: 'blur' }],
        city: [{ required: true, message: 'Please enter the city', trigger: 'blur' }],
        state: [{ required: true, message: 'Please enter the state', trigger: 'blur' }],
        addressEn: [{ required: true, message: 'Please enter the address', trigger: 'blur' }],
        zip: [{ required: true, message: 'Please enter the Postal Code', trigger: 'blur' }],
        url: [{ required: true, message: 'Please enter the Website URL', trigger: 'blur' }],
        position: [{ required: true, message: 'Please enter the Payment Position', trigger: 'blur' }],
        fullName: [{ required: true, message: 'Please enter the Payment Fullname', trigger: 'blur' }]
        //phones: [{ required: true, message: 'Please enter the Phone Number', trigger: 'blur' }],
        //emails: [{ required: true, validator: validateEmail, trigger: 'blur' }],
      },
    }
  },
  computed: {},
  created() {
    this.setContact()
  },
  methods: {
    async setContact() {
      let res = await updateAccount(this.settingForm)
      if (res && res.code) {
        this.settingForm = {
          companyNameEn: res.data.companyNameEn,
          country: res.data.country,
          city: res.data.city,
          state: res.data.state,
          addressEn: res.data.addressEn,
          zip: res.data.zip,
          url: res.data.url,
          addressEnSecond:res.data.addressEnSecond,
          position:res.data.position,
          fullName:res.data.fullName,
        //   phones: res.data.phones,
        //   emails: res.data.emails,
          type: 0,
        }
        this.phones=res.data.phones?res.data.phones:[]
        this.emails=res.data.emails?res.data.emails:[]
      }
    },
    blurPhone() {
        if(this.phone)this.phones.push(this.phone)
        this.phone=''
    },
    blurEmail() {
        if(this.email)this.emails.push(this.email)
        this.email=''
    },
    //编辑
    editForm() {
      this.isEdit = false
    },
    //提交编辑
    async submitForm() {
      this.settingForm.type = 1
      this.settingForm.phones=this.phones
      this.settingForm.emails=this.emails
      this.phone=''
      this.email=''
      let res = await updateAccount(this.settingForm)
      if (res && res.code) {
        this.$message({
          message: res.msg,
          center: true,
          type: 'success',
        })
        this.isEdit = true
      }
    },
    handleClose(tag) {
      this.phones.splice(this.phones.indexOf(tag), 1)
    },
    handleClose1(tag) {
      this.emails.splice(this.emails.indexOf(tag), 1)
    },
  },
}
</script>
<style scoped lang="less">
.right_cont_box {
  padding: 30px;
}

.right_cont {
  margin-top: 12px;
}

.title {
  margin-bottom: 20px;
}

.title_txt {
  font-size: 16px;
  font-family: 'Arial Bold';
}

.title_txt img {
  width: 20px;
  margin-right: 8px;
}

.btn_export {
  margin-right: 0;
  margin-left: auto;
}

.asset_form .el-form-item:not(:last-child) {
  border-bottom: 1px solid #e7eaed;
  padding-bottom: 12px;
  margin-bottom: 12px;
}

.asset_form .el-form-item:last-child {
  margin-bottom: 0;
}

.asset_form /deep/ .el-form-item .el-input__inner {
  border-width: 0;
}

.asset_form /deep/ .el-form-item__error {
  padding-top: 0;
}

.asset_form /deep/ .el-form-item__label {
  color: #8497ab;
}

.asset_form /deep/ .el-input.is-disabled .el-input__inner {
  background-color: #fff;
  color: #022955;
}

.btn_save {
  background: #e7eaed;
  color: #022955;
}
.phone1 {
  display: flex;
  padding: 0 15px;
  .el-tag {
    // width: 120px;
    height: 40px;
    background: #edf9ffff;
    border: 1px solid #1290c9;
    border-radius: 12px;
    border: 1px solid #e7eaed;
    font-family: ArialMT;
    color: #022955ff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
}
.phone {
  display: flex;
  padding: 0 15px;
  .el-tag {
    // width: 120px;
    height: 40px;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #e7eaed;
    font-family: ArialMT;
    color: #022955ff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.input-new-tag {
  // width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
